<template>
  <div class="liff-reservation-service">
    <!-- 先關掉有分類的版本 -->
    <div class="liff-reservation-service__category-list" v-if="false">
      <div v-for="category in categories" :key="category.id" class="liff-reservation-service__category" @click="scrollToService(category.id)">{{ category.name }}</div>
    </div>
    <div class="liff-reservation-service__item" :class="{ 'need-bottom': chosenServices.length > 0 }" v-if="false">
      <b-card v-for="category in categories" :key="category.id"
              class="liff-reservation-service__card"
              :class="{ 'open': openCategories[category.id] }"
              :ref="category.id">
        <template #header>
          <div class="s-flex s-justify-between s-items-center" @click="toggle(category.id)">
            <div>{{ category.name }}</div>
            <BIconChevronDown :height="15" class="liff-reservation-service__card-icon" />
          </div>
        </template>

        <div class="liff-reservation-service__content">
          <div v-for="service in category.services" :key="service.id">
            <input type="checkbox" :id="service.id" class="liff-reservation-service__checkbox" :value="service.name" v-model="chosenServices">
            <label :for="service.id" class="liff-reservation-service__content-item" :class="{ 'active': chosenServices.includes(service.name) }">
              <img class="liff-reservation-service__content-item-img" :src="service.img" alt="">
              <div class="liff-reservation-service__content-item-info">
                <div class="s-flex s-flex-col s-gap-2">
                  <div>{{ service.name }}</div>
                  <div class="s-text-secondary s-text-sm">{{ service.description }}</div>
                </div>
                <div class="s-text-primary">{{ service.price }}<span class="s-text-sm">起</span></div>
              </div>
              <BIconCheckCircleFill v-if="chosenServices.includes(service.name)" :height="20" class="s-text-primary liff-reservation-service__content-item-check" />
            </label>
          </div>
        </div>
      </b-card>
    </div>
    <div class="text-center my-2" v-if="fetching">
      <b-spinner class="align-middle spinner-border"></b-spinner>
    </div>
    <!-- 沒有分類的 -->
    <div class="liff-reservation-service__item" :class="{ 'need-bottom': chosenServices.length > 0 }" v-else>
      <b-card v-for="item in collectionItems" class="liff-reservation-service__card" :key="item.id">
        <input type="checkbox" :id="item.id" class="liff-reservation-service__checkbox" :value="item.id" v-model="chosenServices">
        <label :for="item.id" class="liff-reservation-service__content-item" :class="{ 'active': chosenServices.includes(item.id) }">
          <img class="liff-reservation-service__content-item-img" :src="getCollectionData(item, 'image')" alt="">
          <div class="liff-reservation-service__content-item-info">
            <div class="s-flex s-flex-col s-gap-2">
              <div>{{ getCollectionData(item, 'title') }}</div>
              <div class="s-text-secondary s-text-sm">{{ getCollectionData(item, 'description') }}</div>
            </div>
            <div class="s-text-primary">{{ getCollectionData(item, 'price') }}<span class="s-text-sm">起</span></div>
          </div>
          <BIconCheckCircleFill v-if="chosenServices.includes(item.id)" :height="20" class="s-text-primary liff-reservation-service__content-item-check" />
        </label>
      </b-card>
    </div>

    <div v-if="chosenServices.length > 0" class="liff-reservation-service__confirm">
      <div class="s-flex s-justify-between s-items-center s-mb-3">
        <div class="s-font-medium">已選 {{ chosenServices.length }} 項：<span class="s-text-primary">{{ chosenServicesText }}</span></div>
        <div class="s-text-primary cursor-pointer s-font-medium s-flex-shrink-0" @click="chosenServices = []">重選</div>
      </div>
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="prevStep"
        >
        上一步
        </button>
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>

    <Popup ref="popup" title="預約注意事項" panelHeight="auto">
      <div class="s-px-3 s-mb-6">
        <ul class="s-list-decimal text-left" style="padding-left: 12px;">
          <li>預約時間為今日起30天內</li>
          <li>如欲取消預約或更改時間可至確認郵件中點選取消連結或撥打專線由專人為您服務，使用上有任何問題歡迎撥打客服專線。<br/>客服電話： (02) 2222-2222</li>
        </ul>
      </div>
      <div slot="actions">
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="closePopup"
        >
          知道了
        </button>
      </div>
    </Popup>
  </div>
</template>

<script>
  import { BIconChevronDown, BIconCheckCircleFill } from 'bootstrap-vue'
  import Popup from '@/components/Page/Liff/Shared/Popup.vue';
  import bookingPresetApi from '@/apis/liff/v2/booking-preset';
  import collectionApi from '@/apis/liff/v2/collection';
  import reservationMixin from "@/mixins/liff/reservation";

  export default {
    mixins: [reservationMixin],
    components: {
      BIconChevronDown,
      BIconCheckCircleFill,
      Popup
    },
    data() {
      return {
        chosenServices: [],
        categories: [
          {
            id: 'service_1',
            name: '剪髮',
            services: [
              {
                id: 'service_1_1',
                name: '精緻剪髮',
                price: 1000,
                description: '',
                img: 'https://picsum.photos/200/300'
              },
              {
                id: 'service_1_2',
                name: '精緻修髮',
                price: 1000,
                description: '寫一些備註讓人客得到更多',
                img: 'https://picsum.photos/200/300'
              }
            ],
          },
          {
            id: 'service_2',
            name: '染髮',
            services: [
              {
                id: 'service_2_1',
                name: '歐系染髮',
                price: 700,
                description: '',
                img: 'https://picsum.photos/200/300'
              },
              {
                id: 'service_2_2',
                name: '日系染髮',
                price: 1200,
                description: '',
                img: 'https://picsum.photos/200/300'
              }
            ]
          },
          {
            id: 'service_3',
            name: '燙髮'
          },
          {
            id: 'service_4',
            name: '護髮'
          },
          {
            id: 'service_5',
            name: '造型'
          },
          {
            id: 'service_6',
            name: '洗髮'
          }
        ],
        openCategories: {},
        collectionItems:[],
        bookingPreset: {},
        fetching: false
      }
    },
    computed: {
      chosenServicesText() {
        const items = this.collectionItems.filter((item) => this.chosenServices.includes(item.id))
        return items.map((item) => {
          return this.getCollectionData(item, 'title')
        }).join('、');
      },
      branchId() {
        return this.$route.query.branch_id
      },
      staffId() {
        return this.$route.query.staff_id
      }
    },
    mounted() {
      this.fetching = true
      this.init();
      this.$refs.popup.open();
      this.fetching = false
    },
    watch: {
      chosenServices: function(val) {
        if (this.bookingPreset?.config?.service?.can_multiple_select) {
          return;
        }
        if (val.length > 1) {
          val.shift()
        }
      }
    },
    methods: {
      formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let result = '';
        if (hours > 0) {
          result += `${hours}小時`;
        }
        if (remainingMinutes > 0) {
          result += `${remainingMinutes}分鐘`;
        }

        return result || '0分鐘';
      },
      getCollectionData(item, type) {
        if (type == 'image') {
          return item.data.find((d) => d.field_id == this.bookingPreset?.config?.service?.image_item_id)?.value
        } else if (type == 'title') {
          return item.data.find((d) => d.field_id == this.bookingPreset?.config?.service?.title_item_id)?.value
        } else if (type == 'description') {
          return item.data.find((d) => d.field_id == this.bookingPreset?.config?.service?.description_item_id)?.value
        } else if (type == 'price') {
          return item.data.find((d) => d.field_id == this.bookingPreset?.config?.service?.price_item_id)?.value
        } else if (type == 'duration') {
          return item.data.find((d) => d.field_id == this.bookingPreset?.config?.service?.duration_item_id)?.value
        }
      },
      async init() {
        await this.fetchCollectionItems();
      },
      async fetchCollectionItems() {
        const data = await bookingPresetApi.get(this.$route.params.bookingPresetId);
        this.bookingPreset = data
        const colData = await collectionApi.getCollection(this.bookingPreset.config.service.collection_id)
        this.collectionItems = colData.data.data.items
      },
      toggle(card) {
        this.$set(this.openCategories, card, !this.openCategories[card]);
      },

      scrollToService(id) {
        this.toggle(id);

        this.$nextTick(() => {
          const element = this.$refs[id][0];
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
      },
      nextStep() {
        this.set('service', {
          id: this.chosenServices,
          name: this.chosenServicesText,
          price: this.chosenServices.reduce((acc, id) => {
            const item = this.collectionItems.find((item) => item.id == id)
            return acc + parseInt(this.getCollectionData(item, 'price'))
          }, 0),
          duration: this.chosenServices.reduce((acc, id) => {
            const item = this.collectionItems.find((item) => item.id == id)
            return acc + parseInt(this.getCollectionData(item, 'duration'))
          }, 0)
        })
        this.nextStepByList("LiffReservationService", {
          branch_id: this.branchId,
          staff_id: this.staffId
        })
      },
      prevStep() {
        this.prevStepByList("LiffReservationService", {
          branch_id: this.branchId,
          staff_id: this.staffId
        })
      },

      closePopup () {
        this.$refs.popup.close();
      }
    }
  }
</script>

<style lang="scss" scoped>
.liff-reservation-service {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__category-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    overflow-x: auto;
  }

  &__category {
    border: 1px solid rgba(99, 99, 102, 0.6);
    border-radius: 50px;
    padding: 7px 16px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.need-bottom {
      margin-bottom: 150px;
    }
  }
  &__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

		.card-header {
			border-radius: 10px 10px 0 0;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
      border: none;
		}
    .card-body {
      padding: 0;
    }
    &__card-icon {
      transition: transform 0.3s ease-in-out;
    }
    &.open {
      .liff-reservation-service__content {
        display: flex;
      }
      .liff-reservation-service__card-icon {
        transform: rotate(180deg);
      }
    }
	}
  &__checkbox {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px 16px;
    display: none;
    &-item {
      display: flex;
      align-items: center;
      border: 1px solid #E5E5EA;
      border-radius: 10px;
      padding: 12px;
      color: #2C2C2E;
      font-size: 18px;
      font-weight: 500;
      gap: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #F5F5F5;
      }
      &.active {
        border: 2px solid var(--s-primary);
      }
      &-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
      }
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
      }
      &-check {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

.spinner-border {
  border-width: 4px;
}
</style>
